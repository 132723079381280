<template>
  <div class="bitts-count" :class="classes">
    <badge
      :count="count"
      :overflow-count="99"
      :number-style="offsetStyle"
      :show-zero="showZero"
    >
      <slot />
    </badge>
  </div>
</template>

<script setup lang="ts">
import { Badge } from 'ant-design-vue';
import { computed, useSlots } from 'vue';

const _AFFIX_MODE = {
  left: 'left',
  middle: 'middle',
  right: 'right',
} as const;
type AffixModeType = (typeof _AFFIX_MODE)[keyof typeof _AFFIX_MODE];

const _TYPE = {
  default: 'default',
  danger: 'danger',
  neutral: 'neutral',
};
type TypeType = (typeof _TYPE)[keyof typeof _TYPE];

interface Props {
  affixMode?: AffixModeType;
  count?: number | string;
  disabled?: boolean;
  offset?: [number, number];
  showZero?: boolean;
  type?: TypeType;
}

const props = withDefaults(defineProps<Props>(), {
  affixMode: 'middle',
  count: 0,
  disabled: false,
  offset: () => [0, 0],
  showZero: false,
  type: 'default',
});

const slots = useSlots();

const isEmpty = computed(() => !slots.default);
const classes = computed(() => {
  return [
    `count-affix-${props.affixMode}`,
    props.type,
    props.disabled ? 'disabled' : null,
    isEmpty.value ? 'empty' : null,
  ];
});
const offsetStyle = computed(() => {
  if (isEmpty.value) return {};
  const [_offsetX, offsetY] = props.offset;
  const marginX = props.affixMode === 'left' ? 'margin-left' : 'margin-right';
  const offsetX = props.affixMode === 'left' ? -_offsetX : _offsetX;
  return {
    'margin-top': `${offsetY}px`,
    [marginX]: `${offsetX}px`,
  };
});
</script>

<style lang="pcss">
.bitts-count {
  --affix-right-offset: calc(0.5ex + 4px);
  --affix-left-offset: calc(-1 * var(--affix-right-offset));

  @apply inline-block;

  .ant-badge.ant-badge-not-a-wrapper .ant-scroll-number {
    @apply flex;
  }

  .ant-badge-count {
    @apply flex items-center justify-center
      h-16 min-w-0 p-4
      rounded-full
      text-xs font-bold leading-loose;
    box-shadow: none;
  }

  .ant-badge-count {
    @apply px-6;
  }

  &.empty .ant-badge-count {
    transform: translateY(-2px);
  }

  &.count-affix-left .ant-badge-count {
    left: 100%;
    right: auto;
    transform: translate(var(--affix-left-offset), -50%);
    transform-origin: 0 0;

    &.ant-badge-zoom-appear,
    &.ant-badge-zoom-enter {
      animation-name: bittsZoomBadgeInAffixedLeft;
    }
  }

  &.count-affix-right .ant-badge-count {
    transform: translate(var(--affix-right-offset), -50%);

    &.ant-badge-zoom-appear,
    &.ant-badge-zoom-enter {
      animation-name: bittsZoomBadgeInAffixedRight;
    }
  }

  &.default sup.ant-badge-count {
    @apply bg-upsell-background-medium;
  }
  &.danger sup.ant-badge-count {
    @apply bg-danger-background-medium;
  }
  &.neutral sup.ant-badge-count {
    @apply bg-neutral-background text-neutral-text;
  }
  &.disabled sup.ant-badge-count {
    @apply bg-neutral-background-disabled text-neutral-600;
  }

  @keyframes bittsZoomBadgeInAffixedRight {
    0% {
      transform: scale(0) translate(var(--affix-right-offset), -50%);
      opacity: 0;
    }
    100% {
      transform: scale(1) translate(var(--affix-right-offset), -50%);
    }
  }

  @keyframes bittsZoomBadgeInAffixedLeft {
    0% {
      transform: scale(0) translate(var(--affix-left-offset), -50%);
      opacity: 0;
    }
    100% {
      transform: scale(1) translate(var(--affix-left-offset), -50%);
    }
  }
}
</style>
